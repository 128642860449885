import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import FiftyFifty from '../components/fiftyfifty'

const News = (props) => {

  console.log(props)

  return(
  <section>
    <SEO title="Latest news" description='Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings' />
   
    <FiftyFifty  data={props.data.allPosts} type="posts" />
  </section>
  )
}
export const query  = graphql`
{
  allPosts {
    edges {
      node {
        title
        content
        intro
        id
        localImage {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`
export default News